<template>
    <HomeContainer>
        <template v-slot:header>
            <Header />
        </template>

        <template v-slot:main>
            <Component :is="landingPage" />
        </template>

        <template v-slot:footer>
            <Footer />
        </template>
    </HomeContainer>
</template>

<script setup>
    import { computed } from "vue"
    import { useStore } from "vuex"
    import Partners from "@/constants/partner/partner.constants"

    import Header from "@/components/Header/Header.vue"
    import Footer from "@/components/Footer/Footer.vue"
    import LandingPageOldDesign from "@/components/Homepages/mcafee/Homepage.vue"
    import LandingPageNewDesign from "@/components/Homepages/hp"
    import HomeContainer from "@/components/Container/HomeContainer.vue"

    const store = useStore()

    const partnersToRenderNewDesign = [
        Partners.HP,
        Partners.MASTERCARD,
        Partners.SEMPRE_SEGURO,
        Partners.RIGSTAR
    ]

    const landingPage = computed(
        () => partnersToRenderNewDesign.includes(store.getters.getPartner) ? LandingPageNewDesign : LandingPageOldDesign
    )
</script>
